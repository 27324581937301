
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import LinkNumbersTable from '@/components/pages/chats/settings/whatsApp/detailed/LinkNumbersTable.vue'
import type { TableHeaders } from '@/definitions/shared/types'
import { LinkNumbersMock } from '@/definitions/chats/settings/whatsApp/data'

export default defineComponent({
  components: {
    TmButton,
    TmModal,
    LinkNumbersTable,
  },
  setup() {
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'WhatsApp number', value: 'number-slot' },
      { text: 'Display name', value: 'label-slot' },
      { text: 'Status', value: 'status-slot' },
    ])
    const tableItems = ref(LinkNumbersMock)

    const selected = ref([])

    return {
      tableHeaders,
      selected,
      tableItems,
    }
  },
})
